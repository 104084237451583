<template>
  <div class="c_follow">
    <!-- ShareThis BEGIN -->
    <div class="sharethis-inline-share-buttons"></div>
    <!-- ShareThis END -->
  </div>
</template>

<script>
export default {
  name: 'CShareThisInline',
  mounted () {
    if (window && window.__sharethis__) {
      window.__sharethis__.initialize()
    }
  }
}
</script>

<style lang="scss">
.c_follow {
  margin-bottom: 2rem;
}
</style>
