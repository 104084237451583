<template>
  <OButton tag="a" :target="target" :href="href" theme="none" v-on:click="check" :class="classes">
    <div class="c-buy-button">
      <div v-if="bonus_credits>0" class="c-buy-button__credits">
        <span class="c-buy-button__credits__value"><s>{{ credits }}</s></span>
        <span class="c-buy-button__bonus__credits__value">{{ credits + bonus_credits }}</span>
        credits
      </div>
      <div v-else class="c-buy-button__credits">
        <span class="c-buy-button__credits__value">{{ credits }}</span>
        credits
      </div>
      <div class="c-buy-button__cost">${{ cost.toFixed(2) }}</div>
    </div>
    <div class="c-buy-error" v-if="showError">{{ error }}</div>
  </OButton>
</template>

<script>
import { mapGetters } from 'vuex'
import OButton from '../objects/OButton'

export default {
  name: 'CBuyButton',
  components: { OButton },
  props: {
    credits: Number,
    bonus_credits: Number,
    cost: Number,
    target: String,
    value: String,
    always: Boolean,
    isLink: Boolean
  },
  data () {
    return {
      showError: false
    }
  },
  methods: {
    check: function () {
      this.showError = !this.always && this.phones.length === 0
    }
  },
  computed: {
    ...mapGetters({
      email: 'getEmail',
      phones: 'getPhones'
    }),
    href () {
      let url = 'javascript:void(0);'
      if (this.isLink && (this.always || this.phones.length > 0)) {
        url = `/checkout?amount=${this.cost}&credits=${this.credits}&bonus_credits=${this.bonus_credits}`
      }
      return url
    },
    error () {
      let msg = ''
      if (!this.always && this.phones.length === 0) {
        msg = 'You need to complete at least one phone call in our system before you can purchase this price point'
      }
      return msg
    },
    classes () {
      let classes = ['c-buy']
      if (!this.isLink) {
        classes.push('c-buy-cursor-default')
      }
      return classes
    }
  },
  mounted () {
    if (!this.isLink) {
      this.check()
    }
  }
}
</script>

<style lang="scss">
.c-buy-error {
  color: $primary;
  animation: pulse 0.25s 0.125s 2, scale-in 0.125s;
  text-transform: none;
  margin-top: 0.5rem;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    transform-origin: 25% 50%;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0.1)
  }
  100% {
    transform: scale(1);
  }
}

.c-buy {
  padding: 0;
}

.c-buy-cursor-default {
  cursor: default;
}

.c-buy-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0;
  border: 1px solid $primary;
}

.c-buy-button__cost,
.c-buy-button__credits {
  padding: 1rem 0.75rem;
}

.c-buy-button__credits {
  text-transform: lowercase;
}

.c-buy-button__credits__value {
  font-weight: 700;
}

.c-buy-button__bonus__credits__value {
  font-weight: 700;
  margin-left: 0.3rem;
}

.c-buy-button__cost {
  letter-spacing: 0;
  width: 4em;
  color: #fff;
  background-color: $primary;
}
</style>
