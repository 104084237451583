<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <OForm @submit="submit" :class="{submitting}">
    <OFormInput
      type="text"
      v-model="form.code"
      required
      inputClass="u-capitalize"
      autocorrect="off"
      autocapitalize="characters"
      autocomplete="off"
      placeholder="Enter promo code"
      minlength="6"
      pattern="^[a-zA-Z0-9]{6}$"
    >
      Promo code:
      <template v-slot:tooltip>
        We give discount codes and free credit codes vie email.<br/>
        We also send important news for out products.
      </template>
    </OFormInput>
    <OFormError :message="error"></OFormError>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting">
        Claim
      </OFormSubmitButton>
    </FormButtons>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import OForm from '../objects/forms/OForm'
import OFormInput from '../objects/forms/OFormInput'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'
import FormButtons from '../objects/forms/FormButtons'
import OFormError from '../objects/forms/OFormError'

export default {
  name: 'CFormVoucher',
  components: { OFormError, OFormSubmitButton, OFormInput, OForm, FormButtons },
  mixins: [apiForm],
  data: function () {
    return {
      form: {
        email: this.$store.getters.getEmail,
        code: null
      }
    }
  },
  methods: {
    submit () {
      this.$callApi(api => api.voucher(this.form))
    }
  },
  formSubmitted (response) {
    this.form.code = ''
    this.error = response.message
  }
}
</script>

<style lang="scss">
.u-capitalize {
  text-transform: uppercase;
}
</style>
